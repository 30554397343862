/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./submit-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./submit-button.component";
var styles_SubmitButtonComponent = [i0.styles];
var RenderType_SubmitButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubmitButtonComponent, data: {} });
export { RenderType_SubmitButtonComponent as RenderType_SubmitButtonComponent };
function View_SubmitButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.loadingIconClass, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SubmitButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.iconClass, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_SubmitButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [], [[8, "className", 0], [8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubmitButtonComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubmitButtonComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isLoading; _ck(_v, 2, 0, currVal_2); var currVal_3 = !_co.isLoading; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); var currVal_1 = _co.isLoading; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.text; _ck(_v, 5, 0, currVal_4); }); }
export function View_SubmitButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-submit-button", [], null, null, null, View_SubmitButtonComponent_0, RenderType_SubmitButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.SubmitButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubmitButtonComponentNgFactory = i1.ɵccf("app-submit-button", i3.SubmitButtonComponent, View_SubmitButtonComponent_Host_0, { cssClass: "cssClass", isLoading: "isLoading", text: "text", iconClass: "iconClass", loadingIconClass: "loadingIconClass" }, {}, []);
export { SubmitButtonComponentNgFactory as SubmitButtonComponentNgFactory };
