<aside>
  <button type="button" class="btn btn-light text-primary mw-100 d-md-none" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
    <i *ngIf="isCollapsed" class="fas fa-chevron-down"></i> <i *ngIf="!isCollapsed" class="fas fa-chevron-up"></i> &nbsp;Admin Menu
  </button>
  <nav class="list-group" [ngbCollapse]="isCollapsed">
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/carousel"> <i class="far fa-images fa-2x align-middle mr-2 fa-fw"></i>Manage Carousel </a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/categories"> <i class="far fa-folder-open fa-2x align-middle mr-2 fa-fw"></i>Categories </a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/flagged"> <i class="far fa-flag fa-2x align-middle mr-2 fa-fw"></i>Flagged Videos </a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" (click)="reportsRedirect()">
      <i class="far fa-chart-bar fa-2x align-middle mr-2 fa-fw"></i>Analytics Reporting <i class="fas fa-sm p-2 fa-external-link-alt float-right"></i>
    </a>
	  <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/admin-reporting"> <i class="far fa-chart-bar fa-2x align-middle mr-2 fa-fw"></i>Admin Reporting</a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/archives"> <i class="fas fa-archive fa-2x align-middle mr-2 fa-fw"></i>Archives </a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/upload"> <i class="fas fa-upload fa-2x align-middle mr-2 fa-fw"></i>Upload </a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/private-videos"> <i class="fas fa-lock fa-2x align-middle mr-2 fa-fw"></i>Private Videos </a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/inactive-videos"> <i class="fas fa-eye-slash fa-2x align-middle mr-2 fa-fw"></i>Inactive Videos </a>
    <a class="list-group-item list-group-item-action" routerLinkActive="active" routerLink="/admin/queue"> <i class="fas fa-check fa-2x align-middle mr-2 fa-fw"></i>Admin Queue </a>
  </nav>
</aside>
