/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ngx-progressbar/core/ngx-progressbar-core.ngfactory";
import * as i3 from "@ngx-progressbar/core";
import * as i4 from "./layout/header/header.component.ngfactory";
import * as i5 from "./layout/header/header.component";
import * as i6 from "./layout/side-nav/services/side-nav.service";
import * as i7 from "./layout/header-search/services/header-search.service";
import * as i8 from "@angular/router";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "./domain/services/stars.service";
import * as i11 from "./core/xapi/services/xapi.tracking.service";
import * as i12 from "./domain/services/disclaimer.service";
import * as i13 from "./core/user/user.service";
import * as i14 from "./layout/side-nav/side-nav.component.ngfactory";
import * as i15 from "./layout/side-nav/side-nav.component";
import * as i16 from "./domain/services/categories.service";
import * as i17 from "./layout/header-search/directives/header-search-close.directive";
import * as i18 from "./layout/side-nav/directives/side-nav-close.directive";
import * as i19 from "./layout/footer/footer.component.ngfactory";
import * as i20 from "./layout/footer/footer.component";
import * as i21 from "@angular/common";
import * as i22 from "./common/loader/loader.component.ngfactory";
import * as i23 from "./common/loader/loader.component";
import * as i24 from "./domain/services/loader.service";
import * as i25 from "./layout/carousel/carousel.component.ngfactory";
import * as i26 from "./layout/carousel/services/carousel-singleton.service";
import * as i27 from "./domain/services/carousel.service";
import * as i28 from "./layout/carousel/carousel.component";
import * as i29 from "./common/pipes/safe-html/safe-html.pipe";
import * as i30 from "./app.component";
import * as i31 from "./auth/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-progress", [["role", "progressbar"]], [[1, "spinnerPosition", 0], [1, "dir", 0], [1, "thick", 0], [1, "fixed", 0]], null, null, i2.View_NgProgressComponent_0, i2.RenderType_NgProgressComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgProgressComponent, [i3.NgProgress], { color: [0, "color"], spinner: [1, "spinner"] }, null)], function (_ck, _v) { var currVal_4 = "#2096cd"; var currVal_5 = false; _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).spinnerPosition; var currVal_1 = i1.ɵnov(_v, 1).direction; var currVal_2 = i1.ɵnov(_v, 1).thick; var currVal_3 = i1.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i1.ɵdid(1, 5292032, null, 0, i5.HeaderComponent, [i6.SideNavService, i7.HeaderSearchService, i8.ActivatedRoute, i9.NgbModal, i10.StarsService, i11.XapiTrackingService, i12.DisclaimerService, i13.UserService], null, null)], null, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-sidenav", [["appHeaderSearchClose", ""]], [[40, "@transform", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_SideNavComponent_0, i14.RenderType_SideNavComponent)), i1.ɵdid(1, 4308992, null, 0, i15.SideNavComponent, [i6.SideNavService, i16.CategoriesService, i8.Router, i13.UserService], null, null), i1.ɵdid(2, 16384, null, 0, i17.HeaderSearchCloseDirective, [i7.HeaderSearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toggleState; _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["appHeaderSearchClose", ""], ["appSideNavClose", ""], ["class", "text-center text-muted small text-uppercase pb-0"], ["id", "disclaimer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i18.SideNavCloseDirective, [i6.SideNavService], null, null), i1.ɵdid(2, 16384, null, 0, i17.HeaderSearchCloseDirective, [i7.HeaderSearchService], null, null), (_l()(), i1.ɵted(-1, null, [" The content on this website is confidential and should not be posted on other sites or shared\n"]))], null, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-footer", [["appHeaderSearchClose", ""], ["appSideNavClose", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i19.View_FooterComponent_0, i19.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i20.FooterComponent, [i8.Router, i1.ElementRef, i16.CategoriesService, i13.UserService], null, null), i1.ɵdid(2, 16384, null, 0, i18.SideNavCloseDirective, [i6.SideNavService], null, null), i1.ɵdid(3, 16384, null, 0, i17.HeaderSearchCloseDirective, [i7.HeaderSearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(5, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "fordloader", [], null, null, null, i22.View_LoaderComponent_0, i22.RenderType_LoaderComponent)), i1.ɵdid(7, 114688, null, 0, i23.LoaderComponent, [i24.LoaderService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 7, "main", [["appHeaderSearchClose", ""], ["appSideNavClose", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i18.SideNavCloseDirective, [i6.SideNavService], null, null), i1.ɵdid(10, 16384, null, 0, i17.HeaderSearchCloseDirective, [i7.HeaderSearchService], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-carousel", [], [[8, "hidden", 0]], [["document", "resetSlides"]], function (_v, en, $event) { var ad = true; if (("document:resetSlides" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).handleResetSlides() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i25.View_CarouselComponent_0, i25.RenderType_CarouselComponent)), i1.ɵprd(512, null, i26.CarouselSingletonService, i26.CarouselSingletonService, [i27.CarouselService]), i1.ɵdid(13, 245760, null, 0, i28.CarouselComponent, [i26.CarouselSingletonService, i29.SafeHtmlPipe, i13.UserService], null, null), (_l()(), i1.ɵeld(14, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(15, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(17, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(19, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isEmbed; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isEmbed; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.isEmbed; _ck(_v, 5, 0, currVal_2); _ck(_v, 7, 0); _ck(_v, 13, 0); _ck(_v, 15, 0); var currVal_4 = !_co.isEmbed; _ck(_v, 17, 0, currVal_4); var currVal_5 = !_co.isEmbed; _ck(_v, 19, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.showCarousel; _ck(_v, 11, 0, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i30.AppComponent, [i31.AuthService, i13.UserService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i30.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
